import {
    faChevronLeft,
    faChevronRight,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useGetAnswers from "actions/questionnaire/useGetAnswers";
import Layout from "components/Layout/Layout";
import Button from "components/UI/Button/Button";
import { useContext, useEffect, useState } from "react";
import AllStatementsView from "./AllStatementsView/AllStatementsView";
import OverlapView from "./OverlapView/OverlapView";
import styles from "./Results.module.scss";
import { ResultsContext } from "../../context/ResultsContext";
import useGetThemes from "actions/theme/useGetThemes";
import useGetResponses from "actions/questionnaire/useGetResponses";
import useGetQuestions from "actions/questionnaire/useGetQuestions";

type ViewModes = "overlap" | "allStatements";

export default function Results() {
    const { data: answers } = useGetAnswers();
    const { data: themes } = useGetThemes();
    const { data: responses } = useGetResponses();
    const { data: questions } = useGetQuestions();
    const {
        setAnswers,
        setThemes,
        setResponses,
        selectedTheme,
        setSelectedTheme,
    } = useContext(ResultsContext);

    console.log(questions);

    useEffect(() => {
        if (answers && themes && responses) {
            setAnswers(answers);
            setThemes(themes);
            setResponses(responses);
        }
    }, [answers, themes, responses]);

    const [viewMode, setViewMode] = useState<ViewModes>("overlap");

    return (
        <Layout>
            <div className={styles.results}>
                <div className={styles.center}>
                    <div className={styles.top}>
                        <div className={styles.tabs}>
                            <Button
                                variant={
                                    selectedTheme === null ? "primary" : "white"
                                }
                                onClick={() => setSelectedTheme(null)}
                            >
                                Alle thema's
                            </Button>
                            {themes?.map((theme) => (
                                <Button
                                    variant={
                                        selectedTheme === theme.id
                                            ? "primary"
                                            : "white"
                                    }
                                    onClick={() => setSelectedTheme(theme.id)}
                                    key={theme.id}
                                >
                                    {theme.name}
                                </Button>
                            ))}
                        </div>
                        <div className={styles.viewModeButton}>
                            {viewMode === "overlap" ? (
                                <Button
                                    onClick={() => setViewMode("allStatements")}
                                    variant="white"
                                >
                                    Alle stellingen
                                    <FontAwesomeIcon icon={faChevronRight} />
                                </Button>
                            ) : (
                                <Button
                                    onClick={() => setViewMode("overlap")}
                                    variant="white"
                                >
                                    <FontAwesomeIcon icon={faChevronLeft} />
                                    Terug naar overzicht
                                </Button>
                            )}
                        </div>
                    </div>
                    {viewMode === "overlap" ? (
                        <OverlapView />
                    ) : (
                        <AllStatementsView />
                    )}
                </div>
            </div>
        </Layout>
    );
}
