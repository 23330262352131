import styles from "./Header.module.scss";

export default function Header() {
    return (
        <div className={styles.Header}>
            <h4>Stelling</h4>
            <h4>Antwoorden</h4>
            <h4>Thema</h4>
        </div>
    );
}
