import { QuestionsType } from "typings/Database/Questions";
import styles from "./Bar.module.scss";

export default function Bar({ question }: { question: QuestionsType }) {
    return (
        <div className={styles.bar}>
            <h4>
                {question?.theme_id}.{question?.id}
            </h4>
            <p>{question?.analysis_name}</p>
        </div>
    );
}
