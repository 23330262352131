import BreadCrumbs from "components/UI/BreadCrumbs/BreadCrumbs";
import { QuestionsType } from "typings/Database/Questions";
import styles from "./StatementBar.module.scss";
import { BarChart } from "../BarChart/BarChart";

type StatementBarProps = {
    question: QuestionsType;
    themes?: ThemeType[];
};

export default function StatementBar({ question, themes }: StatementBarProps) {
    const theme = themes?.find((theme) => theme.id === question.theme_id);
    return (
        <div className={styles.StatementBar}>
            <p>{question.analysis_name}</p>
            <div className={styles.barChart}>
                <BarChart barChartData={question.bar_chart_data} />
            </div>
            <div className={styles.breadCrumbs}>
                <BreadCrumbs
                    crumbs={[`${theme?.name}`, `${question.sub_theme}`]}
                />
            </div>
        </div>
    );
}
