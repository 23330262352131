import useGetQuestionsBarChartData from "actions/questionnaire/useGetQuestionsAndBarChartData";
import React, { useContext, useEffect, useState } from "react";
import styles from "./AllStatements.module.scss";
import Header from "./components/Header/Header";
import StatementBar from "./components/StatementBar/StatementBar";
import useGetThemes from "actions/theme/useGetThemes";
import { ResultsContext } from "context/ResultsContext";
import { QuestionsType } from "typings/Database/Questions";
import { useParams } from "@tanstack/react-router";

export default function AllStatements() {
    const { questionnaireId } = useParams();
    const {
        data: questionData,
        refetch,
        isFetching,
    } = useGetQuestionsBarChartData(questionnaireId);
    const [questions, setQuestions] = useState<QuestionsType[] | undefined>(
        questionData,
    );
    const { selectedTheme } = useContext(ResultsContext);
    const { data: themes } = useGetThemes();

    useEffect(() => {
        refetch();
    }, [questionnaireId]);

    useEffect(() => {
        if (questionData && !selectedTheme) {
            setQuestions(questionData);
        } else if (questionData && selectedTheme !== 0) {
            setQuestions(
                questionData.filter(
                    (question) => question.theme_id === selectedTheme,
                ),
            );
        }
    }, [questionData, selectedTheme, isFetching, questionnaireId]);

    return (
        <div className={styles.AllStatements}>
            <div className={styles.block}>
                <Header />
                <hr />
                {questions?.map((question, index) => (
                    <React.Fragment key={index}>
                        <StatementBar themes={themes} question={question} />
                        <hr />
                    </React.Fragment>
                ))}
            </div>
        </div>
    );
}
