import { useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "@tanstack/react-router";
import useGetQuestionnaireUsers from "actions/questionnaire/useGetQuestionnaireUsers";
import { getUser } from "actions/user/useGetUser";
import axios from "axios";
import Layout from "components/Layout/Layout";
import { useAuth } from "context/AuthContext";
import { useEffect } from "react";

export default function Home() {
    const { user, setUser } = useAuth();
    const { data: questionnaireUsers } = useGetQuestionnaireUsers();

    const questionnaireId = questionnaireUsers?.map((questionnaireUser) => {
        return questionnaireUser.questionnaire_id;
    });

    const navigate = useNavigate();

    useEffect(() => {}, []);

    const queryClient = useQueryClient();
    useEffect(() => {
        if (!user) {
            const token = localStorage.getItem("authToken");
            if (token) {
                axios.defaults.headers.common.Authorization = `Bearer ${token}`;
                queryClient
                    .fetchQuery({
                        queryKey: ["user"],
                        queryFn: getUser,
                    })
                    .then((data) => {
                        setUser(data);
                        if (data?.role === "admin") {
                            navigate({ to: "/enquetes-overzicht" });
                        } else {
                            navigate({ to: `/resultaten/${questionnaireId}` });
                        }
                    })
                    .catch((error) => {
                        console.error("Failed to fetch user data", error);
                        if (error.response?.status === 401) {
                            localStorage.removeItem("authToken");
                        }
                    });
            } else {
                navigate({ to: "/login" });
            }
        }
    }, [user, queryClient, setUser]);

    return (
        <Layout>
            <h1>Welcome home</h1>
        </Layout>
    );
}
