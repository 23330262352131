import { Link, useParams } from "@tanstack/react-router";
import useAddResponse from "actions/questionnaire/useAddResponse";
import useGetQuestionnaireByUrl from "actions/questionnaire/useGetQuestionnaireByUrl";
import useGetThemes from "actions/theme/useGetThemes";
import Layout from "components/Layout/Layout";
import BreadCrumbs from "components/UI/BreadCrumbs/BreadCrumbs";
import Button from "components/UI/Button/Button";
import PageIndicator from "components/UI/PageIndicator/PageIndicator";
import { useEffect } from "react";
import findMatchingType from "../../utilities/findMatchingType";
import styles from "./Welcome.module.scss";

export default function Welcome() {
    const { token } = useParams();
    const { data: questionnaire } = useGetQuestionnaireByUrl(token);
    const { data: themes } = useGetThemes();
    const { mutateAsync: addResponse } = useAddResponse();

    const type = findMatchingType(token, questionnaire);

    const questionnaireIsOpen = questionnaire?.open === 1;

    const transformType = (type?: string) => {
        switch (type) {
            case "experienced":
                return "Ervaren";
            case "realized":
                return "Gerealiseerd";
            case "intended":
                return "Beoogd";
        }
    };

    // useEffect(() => {
    //     localStorage.removeItem("ResponseId");
    // }, []);

    useEffect(() => {
        if (questionnaire) {
            const storedData = localStorage.getItem("ResponseId");
            // console.log("storedData", storedData);
            if (!storedData) {
                addResponse({
                    questionnaire_id: questionnaire.id!,
                    type: type,
                }).then((res) => {
                    // console.log("response", res);
                    localStorage.setItem("ResponseId", JSON.stringify(res.id));
                    // const storedDataResponse =
                    //     localStorage.getItem("ResponseId");
                    // console.log("storedDataResponse", storedDataResponse);
                });
            }
        }
    }, [questionnaire]);

    return (
        <Layout>
            <div className={styles.welcome}>
                {questionnaireIsOpen ? (
                    <>
                        <BreadCrumbs
                            crumbs={[
                                `${questionnaire?.school_name}`,
                                `${transformType(type)} perspectief`,
                            ]}
                        />
                        <div className={styles.center}>
                            <h2 className={styles.heading}>Welkom!</h2>
                            <div className={styles.block}>
                                <p>
                                    Beste{" "}
                                    {type === "experienced"
                                        ? questionnaire?.experienced_role_name
                                        : type === "realized"
                                          ? questionnaire?.realized_role_name
                                          : type === "intended"
                                            ? questionnaire?.intended_role_name
                                            : ""}{" "}
                                    perspectief van {questionnaire?.school_name}
                                    .<br></br>
                                    <br></br>
                                    Binnen onze opleidingen wordt elke dag
                                    gewerkt aan het realiseren van hoogwaardig
                                    onderwijs en onderzoek. Design Based
                                    Education als centraal onderwijsconcept is
                                    één van de profielkenmerken die aan de
                                    realisatie daarvan een bijdrage kan leveren.
                                    <br></br>
                                    <br></br>
                                    Om als opleiding inzicht te krijgen in de
                                    mate waarin hoogwaardig onderwijs ook echt
                                    gerealiseerd kan worden binnen de opleiding,
                                    heeft het lectoraat DBE deze
                                    zelfevaluatie-tool ontwikkeld. De resultaten
                                    dienen als startpunt om met elkaar in
                                    gesprek te gaan aan de hand van een aantal
                                    thema’s over onderwijskwaliteit en de mate
                                    waarin DBE hieraan een bijdrage levert.
                                    <br></br>
                                    <br></br>
                                    Een belangrijk uitgangspunt hierbij is dat
                                    onderwijskwaliteit relatief is. Dat betekent
                                    dat er niet één waarheid is als het gaat om
                                    onderwijskwaliteit en hoe DBE hieraan een
                                    bijdrage kan leveren: elke opleiding maakt
                                    hierin eigen keuzes. Het gaat erom die
                                    keuzes te kunnen uitleggen en te zorgen dat
                                    deze richtinggevend zijn bij de uitvoering
                                    van het onderwijs.
                                    <br></br>
                                    <br></br>
                                    Het invullen van deze tool duurt ongeveer 20
                                    minuten.
                                </p>
                            </div>
                            <div className={styles.bottom}>
                                <Link
                                    to={`/enquete-theme/${token}/${themes ? themes[0]?.id : 0}`}
                                    className="[&.active]:font-bold"
                                >
                                    <Button>Volgende</Button>
                                </Link>
                                <PageIndicator
                                    currentPage={1}
                                    numberOfPages={
                                        themes && themes.length
                                            ? themes.length + 1
                                            : 0
                                    }
                                />
                            </div>
                        </div>
                    </>
                ) : (
                    <div className={styles.center}>
                        <h2 className={styles.heading}>Enquete gesloten</h2>
                        <div className={styles.block}>
                            <p>
                                Deze enquete is momenteel niet open voor
                                reactie.
                            </p>
                        </div>
                    </div>
                )}
            </div>
        </Layout>
    );
}
