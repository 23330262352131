import React from "react";
import { motion } from "framer-motion";
type Variant = "small" | "normal" | "large";

type Props = {
    children: React.ReactNode;
    variant?: Variant;
    onClick?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
    className: any;
};

const AnimatedDiv = ({
    onClick,
    children,
    variant = "normal",
    className,
}: Props) => {
    return (
        <motion.div
            className={className}
            whileHover={{ scale: 1.01 }}
            whileTap={{ scale: 0.95 }}
            onClick={onClick}
        >
            {children}
        </motion.div>
    );
};

export default AnimatedDiv;
