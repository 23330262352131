import React, { createContext, useState } from "react";

type AuthContextType = {
    user: UserEntity | null;
    setUser: (user: UserEntity) => void;
};

export const AuthContext = createContext<AuthContextType>(
    {} as AuthContextType,
);

type AuthProviderProps = {
    children: React.ReactNode;
};

export function AuthProvider({ children }: AuthProviderProps) {
    const [user, setUser] = useState<UserEntity | null>(null);

    return (
        <AuthContext.Provider
            value={{
                user,
                setUser,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
}

export const useAuth = () => React.useContext(AuthContext);
