import { useMutation } from "@tanstack/react-query";
import axios from "axios";

const addResponse = async (response: ResponseDataType) => {
    const { data } = await axios.post("/response", response);
    return data;
};

export default function useAddResponse() {
    const mutation = useMutation({
        mutationFn: addResponse,
        onSuccess: () => {
            console.log("Response added");
        },
    });

    return mutation;
}
