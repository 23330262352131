import { useNavigate } from "@tanstack/react-router";
import Button from "../Button/Button";
import Input from "../Input/Input";
import Toggle from "../Toggle/Toggle";
import styles from "./EditForm.module.scss";
import DropDown from "../DropDown/DropDown";
import { useEffect, useState } from "react";

type EditFormProps = {
    handleSubmit: (event: React.FormEvent) => void;
    handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    formValues: QuestionnaireType;
    setFormValues: (formValues: any) => void;
    error: string;
    buttonText: string;
    users?: UserEntity[];
};

export default function EditForm({
    handleSubmit,
    buttonText,
    handleChange,
    setFormValues,
    error,
    formValues,
    users,
}: EditFormProps) {
    const navigate = useNavigate();
    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState<
        UserEntity | undefined
    >(undefined);

    useEffect(() => {
        if (selectedOption) {
            setFormValues({
                ...formValues,
                owner_id: selectedOption.id,
            });
            setIsOpen(false);
        }
    }, [selectedOption]);

    return (
        <form onSubmit={handleSubmit}>
            {" "}
            {/* Use onSubmit here */}
            <div className={styles.block}>
                <Input
                    type="text"
                    label="Naam van opleiding"
                    value={formValues.school_name}
                    name="school_name"
                    error={error}
                    onChange={handleChange}
                    placeholder="College"
                />
                <div className={styles.toggleWrapper}>
                    <Toggle
                        label="Open"
                        name="open"
                        value={formValues.open}
                        setFormValues={setFormValues}
                    />
                    <Toggle
                        label="Zichtbaar in dashboard"
                        name="visible_in_dashboard"
                        value={formValues.visible_in_dashboard}
                        setFormValues={setFormValues}
                    />
                </div>
                <Input
                    type="text"
                    label="Ervaren rol naam"
                    value={formValues.experienced_role_name}
                    name="experienced_role_name"
                    error={error}
                    onChange={handleChange}
                    placeholder="Ervaren rol naam"
                />
                <Input
                    type="text"
                    label="Gerealiseerd rol naam"
                    value={formValues.realized_role_name}
                    name="realized_role_name"
                    error={error}
                    onChange={handleChange}
                    placeholder="Gerealiseerd rol naam"
                />
                <Input
                    type="text"
                    label="Beoogd rol naam"
                    value={formValues.intended_role_name}
                    name="intended_role_name"
                    error={error}
                    onChange={handleChange}
                    placeholder="Beoogd rol naam"
                />
                <div>
                    <label htmlFor="owner_id">Eigenaar</label>
                    <DropDown
                        options={users}
                        isOpen={isOpen}
                        setIsOpen={setIsOpen}
                        selectedOption={selectedOption}
                        handleSelect={setSelectedOption as any}
                        type="user"
                    />
                </div>
            </div>
            <div className={styles.buttonWrapper}>
                <Button
                    variant="white"
                    onClick={() => navigate({ to: "/enquetes-overzicht" })}
                >
                    Annuleren
                </Button>
                <Button>{buttonText}</Button>
            </div>
        </form>
    );
}
