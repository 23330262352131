import styles from "./EnqueteHeader.module.scss";

export default function EnqueteHeader({ name }: { name?: string }) {
    return (
        <div className={styles.enqueteHeader}>
            <h3>{name}</h3>
            <p className={styles.optionDescription}>Helemaal mee oneens</p>
            <p className={styles.optionDescription}>Mee oneens</p>
            <p className={styles.optionDescription}>Neutraal</p>
            <p className={styles.optionDescription}>Mee eens</p>
            <p className={styles.optionDescription}>Helemaal mee eens</p>
        </div>
    );
}
