// Components==============
import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import axios from "axios";
// =========================

const getThemes = async () => {
    const { data } = await axios.get(`/themes`);
    return data as ThemeType[];
};

export default function useGetThemes(options?: UseQueryOptions<ThemeType[]>) {
    const query = useQuery<ThemeType[]>({
        queryKey: ["themes"],
        queryFn: getThemes,
        ...options,
    });

    return query;
}
