import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "./Toggle.module.scss";
import {
    faToggleLargeOff,
    faToggleLargeOn,
} from "@fortawesome/pro-regular-svg-icons";

type ToggleProps = {
    label: string;
    name: string;
    value: 1 | 0;
    setFormValues: (formValues: any) => void;
};

export default function Toggle({
    label,
    value,
    setFormValues,
    name,
}: ToggleProps) {
    return (
        <div className={styles.toggleWrapper}>
            <label>{label}</label>
            <FontAwesomeIcon
                onClick={() =>
                    setFormValues((prevFormValues: any) => ({
                        ...prevFormValues,
                        [name]: value ? 0 : 1,
                    }))
                }
                icon={value ? faToggleLargeOn : faToggleLargeOff}
                color={value ? "#32d09d" : "#d9d9d9"}
                size="2x"
            />
        </div>
    );
}
