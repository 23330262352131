import { useEffect, useState } from "react";
import styles from "./OptionsBar.module.scss";
import useAddAnswer from "actions/questionnaire/useAddAnswer";

export default function OptionsBar({
    question,
    type,
    currentThemeIndex,
}: {
    question: any;
    type?: string;
    currentThemeIndex?: number;
}) {
    const activeQuestion = () => {
        switch (type) {
            case "experienced":
                return {
                    name: question.experienced_name,
                    description: question.experienced_description,
                };
            case "intended":
                return {
                    name: question.intended_name,
                    description: question.intended_description,
                };
            case "realized":
                return {
                    name: question.realized_name,
                    description: question.realized_description,
                };
            default:
                return {}; // Return empty object if type doesn't match
        }
    };

    const activeQuestionResult = activeQuestion();

    const [selectedCircle, setSelectedCircle] = useState("");
    const [selectedAnswer, setSelectedAnswer] = useState<number | null>(null);

    const responseId = localStorage.getItem("ResponseId");

    const { mutateAsync: addAnswer } = useAddAnswer();

    const answerOptions = [
        "Helemaal mee oneens",
        "Mee oneens",
        "Neutraal",
        "Mee eens",
        "Helemaal mee eens",
    ];

    const handleAnswerSelection = (answer: string) => {
        switch (answer) {
            case "Helemaal mee oneens":
                setSelectedAnswer(1);
                break;
            case "Mee oneens":
                setSelectedAnswer(2);
                break;
            case "Neutraal":
                setSelectedAnswer(3);
                break;
            case "Mee eens":
                setSelectedAnswer(4);
                break;
            case "Helemaal mee eens":
                setSelectedAnswer(5);
                break;
            default:
                setSelectedAnswer(null);
        }
    };

    function circlePressed(item: string) {
        setSelectedCircle(item);
        handleAnswerSelection(item);
    }

    useEffect(() => {
        if (selectedAnswer !== null) {
            addAnswer({
                question_id: question.id,
                response_id: parseInt(responseId!),
                answer: selectedAnswer,
                type: type!,
                theme_id: currentThemeIndex! + 1,
            }).then((res) => {
                console.log("answer", res);
            });
        }
    }, [selectedAnswer]);

    useEffect(() => {
        setSelectedCircle("");
        setSelectedAnswer(null);
    }, [currentThemeIndex]);

    return (
        <div className={styles.OptionsBar}>
            <div className={styles.text}>
                <p>{activeQuestionResult.name}</p>
                <p className={styles.description}>
                    {activeQuestionResult.description}
                </p>
            </div>
            {answerOptions.map((item, i) => (
                <div
                    key={i}
                    onClick={() => circlePressed(item)}
                    className={`${styles.circle} ${selectedCircle === item ? styles.active : null}`}
                />
            ))}
        </div>
    );
}
