import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import {
    Navigate,
    RouterProvider,
    createRootRoute,
    createRoute,
    createRouter,
} from "@tanstack/react-router";
import { StrictMode } from "react";
import ReactDOM from "react-dom/client";
import About from "routes/About/About";
import Home from "routes/Home/Home";

import axios from "axios";
import { AuthProvider } from "context/AuthContext";
import { AnimatePresence } from "framer-motion";
import End from "routes/End/End";
import Enquete from "routes/Enquete/Enquete";
import EnquetesOverview from "routes/EnquetesOverview/EnquetesOverview";
import Login from "routes/Login/Login";
import Results from "routes/Results/Results";
import Welcome from "routes/Welcome/Welcome";
import "styles/App.scss";
import ResultsProvider from "./context/ResultsContext";
import EditEnquete from "routes/EditEnquete/EditEnquete";
import AddEnquete from "routes/AddEnquete/AddEnquete";

const isAuthenticated = () => {
    const token = localStorage.getItem("authToken");
    const userAuthenticated = sessionStorage.getItem("userAuthenticated");
    return !!token && !!userAuthenticated;
};

const AuthenticatedRoute = ({ component: Component, ...props }: any) => {
    if (!isAuthenticated()) {
        return <Navigate to="/login" replace />;
    }

    return <Component {...props} />;
};

const rootRoute = createRootRoute();

const indexRoute = createRoute({
    getParentRoute: () => rootRoute,
    path: "/",
    component: Home,
});

const aboutRoute = createRoute({
    getParentRoute: () => rootRoute,
    path: "/about",
    component: About,
});

const loginRoute = createRoute({
    getParentRoute: () => rootRoute,
    path: "/login",
    component: Login,
});

const welcomeRoute = createRoute({
    getParentRoute: () => rootRoute,
    path: "/welkom/$token",
    component: Welcome,
});

const enqueteRoute = createRoute({
    getParentRoute: () => rootRoute,
    path: "/enquete-theme/$token/$themeId",
    component: Enquete,
});

const enquetesOverviewRoute = createRoute({
    getParentRoute: () => rootRoute,
    path: "/enquetes-overzicht",
    component: (props) => (
        <AuthenticatedRoute component={EnquetesOverview} {...props} />
    ),
});

const resultsRoute = createRoute({
    getParentRoute: () => rootRoute,
    path: "/resultaten/$questionnaireId",
    component: (props) => <AuthenticatedRoute component={Results} {...props} />,
});

const editEnqueteRoute = createRoute({
    getParentRoute: () => rootRoute,
    path: "/enquete-bewerken/$questionnaireId",
    component: (props) => (
        <AuthenticatedRoute component={EditEnquete} {...props} />
    ),
});

const addEnqueteRoute = createRoute({
    getParentRoute: () => rootRoute,
    path: "/enquete-toevoegen",
    component: (props) => (
        <AuthenticatedRoute component={AddEnquete} {...props} />
    ),
});

const endScreenRoute = createRoute({
    getParentRoute: () => rootRoute,
    path: "/eind",
    component: End,
});

const routeTree = rootRoute.addChildren([
    indexRoute,
    aboutRoute,
    loginRoute,
    welcomeRoute,
    enqueteRoute,
    enquetesOverviewRoute,
    resultsRoute,
    endScreenRoute,
    editEnqueteRoute,
    addEnqueteRoute,
]);

const router = createRouter({ routeTree });

// Register the router instance for type safety
declare module "@tanstack/react-router" {
    interface Register {
        router: typeof router;
    }
}
const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            // Default behavior is to have a stale time of 0. This means that the query will be refetched on every mount.
            staleTime: 10 * 1000 * 60,
        },
    },
});

axios.defaults.baseURL = `/api`;

// Render the app
const rootElement = document.getElementById("app")!;
if (!rootElement.innerHTML) {
    const root = ReactDOM.createRoot(rootElement);
    root.render(
        <StrictMode>
            <QueryClientProvider client={queryClient}>
                <AuthProvider>
                    <ResultsProvider>
                        <AnimatePresence mode="wait">
                            <RouterProvider
                                router={router}
                                key={router.routesById.toString()}
                            />
                        </AnimatePresence>
                    </ResultsProvider>
                </AuthProvider>
            </QueryClientProvider>
        </StrictMode>,
    );
}
