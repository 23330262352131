import { useQueryClient } from "@tanstack/react-query";
import { useNavigate, useParams } from "@tanstack/react-router";
import Layout from "components/Layout/Layout";
import EditForm from "components/UI/EditForm/EditForm";
import { ChangeEvent, useEffect, useState } from "react";
import styles from "./EditEnquete.module.scss";
import useGetQuestionnaireById from "actions/questionnaire/useGetQuestionnaireById";
import useUpdateQuestionnaire from "actions/questionnaire/useUpdateQuestionnaire";
import useGetQuestionnaireUsers from "actions/questionnaire/useGetQuestionnaireUsers";
import useGetUsers from "actions/questionnaire/useGetUsers";

export default function EditEnquete() {
    const { questionnaireId } = useParams();
    const { data: questionnaireUsers } = useGetQuestionnaireUsers();
    const { data: users } = useGetUsers();
    const {
        data: questionnaire,
        isFetched,
        refetch,
    } = useGetQuestionnaireById(questionnaireId);

    const { mutateAsync: updateQuestionnaire } = useUpdateQuestionnaire();
    const [formValues, setFormValues] = useState<QuestionnaireType>({
        id: 0,
        school_name: "",
        open: 0,
        visible_in_dashboard: 0,
        experienced_role_name: "",
        intended_role_name: "",
        realized_role_name: "",
        owner_id: 0,
    });

    useEffect(() => {
        refetch();
    }, [questionnaireId]);

    useEffect(() => {
        if (isFetched && questionnaire && questionnaireUsers && users) {
            setFormValues({
                id: questionnaire.id,
                school_name: questionnaire.school_name,
                open: questionnaire.open,
                visible_in_dashboard: questionnaire.visible_in_dashboard,
                experienced_role_name: questionnaire.experienced_role_name,
                intended_role_name: questionnaire.intended_role_name,
                realized_role_name: questionnaire.realized_role_name,
            });
        }
    }, [questionnaireId, isFetched, questionnaire]);

    const navigate = useNavigate();

    const queryClient = useQueryClient();

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        setFormValues({
            ...formValues,
            [event.target.name]: event.target.value,
        });
    };

    const handleSubmit = (event: React.FormEvent) => {
        event.preventDefault();
        console.log("formValues", formValues);
        updateQuestionnaire(formValues).then(() => {
            queryClient.invalidateQueries({ queryKey: ["questionnaires"] });
            navigate({ to: "/enquetes-overzicht" });
        });
    };

    return (
        <Layout>
            <div className={styles.edit}>
                <div className={styles.center}>
                    <h1>Opleiding bewerken</h1>
                    <EditForm
                        handleSubmit={handleSubmit}
                        handleChange={handleChange}
                        formValues={formValues}
                        setFormValues={setFormValues}
                        buttonText="Opslaan"
                        error=""
                        users={users}
                    />
                </div>
            </div>
        </Layout>
    );
}
