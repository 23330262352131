// Components==============
import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import axios from "axios";
// =========================

const getResponses = async () => {
    const { data } = await axios.get(`/responses`);
    return data as ResponseDataType[];
};

export default function useGetResponses(
    options?: UseQueryOptions<ResponseDataType[]>,
) {
    const query = useQuery<ResponseDataType[]>({
        queryKey: ["responses"],
        queryFn: getResponses,
        ...options,
    });

    return query;
}
