import styles from "./OverlapView.module.scss";
import Bar from "./components/Bar/Bar";
import Overlap from "./components/Overlap/Overlap";
import useGetThemes from "actions/theme/useGetThemes";

export default function OverlapView() {
    const { data: themes } = useGetThemes();

    return (
        <div className={styles.content}>
            <Overlap />
            <div className={styles.right}>
                {themes?.map((theme, i) => {
                    return (
                        <div key={i} className={styles.list}>
                            <h3>{theme.name}</h3>
                            {theme?.questions
                                ?.filter(
                                    (question) =>
                                        question?.theme_id === theme?.id,
                                )
                                ?.map((question, c) => (
                                    <Bar key={c} question={question} />
                                ))}
                        </div>
                    );
                })}
            </div>
        </div>
    );
}
