// Components==============
import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import axios from "axios";
// =========================

export const getUser = async () => {
    const { data } = await axios.get("/user");
    return data as UserEntity;
};

export default function useGetUser(options?: UseQueryOptions<UserEntity>) {
    const query = useQuery<UserEntity>({
        queryKey: ["user"],
        queryFn: getUser,
        ...options,
    });

    return query;
}
