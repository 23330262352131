export const interactiveScale = {
    whileHover: { scale: 1.03 },
    initial: { scale: 1 },
    whileTap: { scale: 0.97 },
};

export const interactiveY = {
    whileHover: { y: -3 },
    initial: { y: 0 },
    whileTap: { y: 2 },
};

export const framerMountAndScale = {
    initial: { opacity: 0, scale: 1 },
    whileHover: { scale: 1.03 },
    whileTap: { scale: 0.97 },
    animate: { opacity: 1 },
    exit: { opacity: 0 },
};

export const framerMountScale = {
    initial: { opacity: 0, scale: 0 },
    whileHover: { scale: 1.03 },
    whileTap: { scale: 0.97 },
    animate: { opacity: 1, scale: 1 },
    exit: { opacity: 0 },
};

export const framerFade = {
    initial: { opacity: 0 },
    animate: { opacity: 1 },
    exit: { opacity: 0 },
};

export const framerAnim = (variants: any) => {
    return {
        variants,
        initial: "initial",
        animate: "animate",
        exit: "exit",
    };
};

export const framerCollapse = {
    animate: {
        height: "auto",
        opacity: 1,
        pointerEvents: "all",
        overflow: "initial",
    },
    initial: {
        height: 0,
        opacity: 0,
        pointerEvents: "none",
        overflow: "hidden",
    },
};
