import { faSignIn, faSignOut } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useQueryClient } from "@tanstack/react-query";
import { Link, useNavigate, useParams } from "@tanstack/react-router";
import useGetQuestionnaires from "actions/questionnaire/useGetQuestionnaires";
import { getUser } from "actions/user/useGetUser";
import axios from "axios";
import Button from "components/UI/Button/Button";
import DropDown from "components/UI/DropDown/DropDown";
import { useAuth } from "context/AuthContext";
import { useEffect, useState } from "react";
import styles from "./Nav.module.scss";
import useGetQuestionnaireUsers from "actions/questionnaire/useGetQuestionnaireUsers";

type Props = {};

export default function Nav({}: Props) {
    const { user, setUser } = useAuth();
    const [selectedOption, setSelectedOption] = useState<
        QuestionnaireType | undefined
    >(undefined);
    const { data: questionnaires } = useGetQuestionnaires();
    const { data: questionnaireUsers } = useGetQuestionnaireUsers();
    console.log(questionnaireUsers);
    const navigate = useNavigate();
    const [isOpen, setIsOpen] = useState(false);

    const { questionnaireId } = useParams();

    const [filteredQuestionnaires, setFilteredQuestionnaires] = useState<
        QuestionnaireType[] | undefined
    >(undefined);

    useEffect(() => {
        if (questionnaires && questionnaireUsers && user?.role !== "admin") {
            const questionnaireIds = questionnaireUsers
                .filter(
                    (questionnaireUser) =>
                        questionnaireUser.user_id === user?.id,
                )
                .map((questionnaireUser) => questionnaireUser.questionnaire_id);

            const filtered = questionnaires.filter(
                (questionnaire) =>
                    questionnaireIds.includes(questionnaire.id) &&
                    questionnaire.visible_in_dashboard === 1,
            );

            setFilteredQuestionnaires(filtered);
        } else {
            setFilteredQuestionnaires(
                questionnaires?.filter(
                    (questionnaire) => questionnaire.visible_in_dashboard === 1,
                ),
            );
        }
    }, [questionnaires, questionnaireUsers, user]);

    const queryClient = useQueryClient();
    useEffect(() => {
        if (!user) {
            const token = localStorage.getItem("authToken");
            if (token) {
                axios.defaults.headers.common.Authorization = `Bearer ${token}`;
                queryClient
                    .fetchQuery({
                        queryKey: ["user"],
                        queryFn: getUser,
                    })
                    .then((data) => {
                        setUser(data);
                    })
                    .catch((error) => {
                        console.error("Failed to fetch user data", error);
                        if (error.response?.status === 401) {
                            localStorage.removeItem("authToken");
                        }
                    });
            }
        }
    }, [user, queryClient, setUser]);

    const handleSelect = (option: QuestionnaireType) => {
        setIsOpen(false);
        // Navigate to selected questionnaire
        navigate({
            to: `/resultaten/${option.id}` as "/resultaten/$questionnaireId",
            params: { questionnaireId: option.id },
        });
    };

    useEffect(() => {
        if (questionnaireId) {
            setSelectedOption(
                filteredQuestionnaires?.find(
                    (questionnaire) =>
                        questionnaire.id.toString() === questionnaireId,
                ),
            );
        }
    }, [questionnaireId, filteredQuestionnaires]);

    const handleLogout = () => {
        localStorage.removeItem("authToken");
        sessionStorage.removeItem("userAuthenticated");
        // Reset axios default header
        delete axios.defaults.headers.common.Authorization;
        // Clear user data from context/state
        setUser(null as any);
        // Redirect to login
        navigate({ to: "/login" });
    };

    return (
        <div className={styles.nav}>
            <div className={styles.info}>
                <img src="/images/nhl-stenden-hogeschool.svg" />
                <h3>Perspectief checker</h3>
            </div>
            {user && (
                <div className={styles.admin}>
                    {filteredQuestionnaires?.length ? (
                        <div className={styles.dropDown}>
                            <DropDown
                                isOpen={isOpen}
                                setIsOpen={setIsOpen}
                                options={filteredQuestionnaires}
                                selectedOption={selectedOption}
                                handleSelect={handleSelect as any}
                            />
                        </div>
                    ) : null}
                    {user?.role === "admin" &&
                        location.pathname !== "/enquetes-overzicht" && (
                            <Link to="/enquetes-overzicht">
                                <Button>Enquetes beheren</Button>
                            </Link>
                        )}
                </div>
            )}
            {!user ? (
                <Link to="/login" className={styles.logout}>
                    Login
                    <FontAwesomeIcon icon={faSignIn} />
                </Link>
            ) : (
                <div className={styles.userInfo}>
                    <p>{user?.name}</p>
                    <div onClick={handleLogout}>
                        <FontAwesomeIcon icon={faSignOut} />
                    </div>
                </div>
            )}
            {/*
            <Link to="/about" className="[&.active]:font-bold">
                About
            </Link> */}
        </div>
    );
}
