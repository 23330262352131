import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "./BreadCrumbs.module.scss";
import { faChevronRight } from "@fortawesome/pro-regular-svg-icons";
import { Fragment } from "react/jsx-runtime";

type Props = {
    crumbs: String[];
};
export default function BreadCrumbs({ crumbs }: Props) {
    return (
        <div className={styles.breadcrumbs}>
            {crumbs.map((crumb, i) => (
                <Fragment key={i}>
                    {i !== 0 && <FontAwesomeIcon icon={faChevronRight} />}
                    <p
                        className={`${styles.crumb} ${i + 1 === crumbs.length ? "" : styles.bold}`}
                    >
                        {crumb}
                    </p>
                </Fragment>
            ))}
        </div>
    );
}
