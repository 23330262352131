// https://www.codevertiser.com/reusable-input-component-react/

import { ChangeEvent, FC } from "react";
import styles from "./Input.module.scss";

interface InputProps {
    type: "text" | "number" | "email" | "password";
    label: string;
    value: string | number;
    name: string;
    placeholder: string;
    error: string;
    disabled?: boolean;
    onChange: (e: ChangeEvent<HTMLInputElement>) => void;
}

const Input: FC<InputProps> = ({
    type,
    label,
    value,
    name,
    placeholder,
    error,
    disabled,
    onChange,
}) => {
    return (
        <div className={styles.inputWrapper}>
            <label htmlFor={label}>{label}</label>
            <input
                type={type}
                id={label}
                value={value}
                name={name}
                placeholder={placeholder}
                onChange={onChange}
                disabled={disabled}
            />
            {error && <p className={styles.error}>{error}</p>}
        </div>
    );
};

export default Input;
