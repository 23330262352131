// Components==============
import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import axios from "axios";
import { QuestionsType } from "typings/Database/Questions";
// =========================

const getQuestionsAndBarChartData = async (school: number) => {
    const { data } = await axios.get(`/overview/${school}`);
    return data as QuestionsType[];
};

export default function useGetQuestionsBarChartData(
    school: number,
    options?: UseQueryOptions<QuestionsType[]>,
) {
    const query = useQuery<QuestionsType[]>({
        queryKey: ["overview"],
        queryFn: () => getQuestionsAndBarChartData(school),
        ...options,
    });

    return query;
}
