import { motion } from "framer-motion";
import React from "react";
import styles from "./Button.module.scss";

type Variant = "primary" | "white" | "lightBlue" | "red" | "turqoise";

type Props = {
    children: React.ReactNode;
    variant?: Variant;
    onClick?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
    to?: string;
    inactive?: boolean;
};

export default function Button({
    children,
    variant = "primary",
    onClick,
    to,
    inactive,
}: Props) {
    return (
        <motion.button
            whileHover={{ scale: 1.03 }}
            whileTap={{ scale: 0.9 }}
            onClick={
                to
                    ? (e) => {
                          e.stopPropagation();
                      }
                    : onClick
            }
            type={to ? "button" : "submit"}
            className={`${styles.button} ${styles[variant]}`}
            style={
                inactive
                    ? {
                          pointerEvents: "none",
                          opacity: 0.5,
                      }
                    : {}
            }
        >
            {children}
        </motion.button>
    );
}
