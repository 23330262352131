import { useMutation } from "@tanstack/react-query";
import axios from "axios";

const addAnswer = async (answer: AnswerType) => {
    const { data } = await axios.post("/answer", answer);
    console.log("answer", answer);
    console.log("data", data);
    return data;
};

export default function useAddAnswer() {
    const mutation = useMutation({
        mutationFn: addAnswer,
        onSuccess: () => {
            console.log("Answer added");
        },
    });

    return mutation;
}
