// Dropdown.tsx
import { faChevronDown } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { motion } from "framer-motion";
import React from "react";
import styles from "./DropDown.module.scss";

interface DropdownProps {
    isOpen: boolean;
    setIsOpen: (open: boolean) => void;
    options: QuestionnaireType[] | UserEntity[] | undefined;
    selectedOption: QuestionnaireType | UserEntity | undefined;
    handleSelect: (option: QuestionnaireType | UserEntity) => void;
    type?: "questionnaire" | "user";
}

const variants = {
    open: {
        rotate: 0,
    },
    closed: {
        rotate: 180,
    },
};

const DropDown: React.FC<DropdownProps> = ({
    isOpen,
    setIsOpen,
    options,
    selectedOption,
    handleSelect,
    type,
}) => {
    return (
        <div className={styles.dropdownContainer}>
            <div
                className={`${
                    type === "user"
                        ? styles.dropdownUser
                        : styles.dropdownHeader
                } ${isOpen ? styles.open : ""}`}
                onClick={() => setIsOpen(!isOpen)}
            >
                {type !== "user" ? (
                    <h4>
                        {selectedOption
                            ? selectedOption.school_name
                            : "Selecteer een opleiding"}
                    </h4>
                ) : (
                    <p>
                        {selectedOption
                            ? selectedOption.name
                            : "Selecteer een gebruiker"}
                    </p>
                )}
                <motion.div
                    transition={{ duration: 0.2 }}
                    variants={variants}
                    animate={isOpen ? "open" : "closed"}
                >
                    <FontAwesomeIcon icon={faChevronDown} />
                </motion.div>
            </div>
            {isOpen && (
                <div className={styles.dropdownOptions}>
                    {options?.map((option) => (
                        <div
                            key={option.id}
                            className={`${type === "user" ? styles.dropdownOptionUser : styles.dropdownOption}`}
                            onClick={() => handleSelect(option)}
                        >
                            {type !== "user" ? option.school_name : option.name}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default DropDown;
