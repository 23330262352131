import { useMutation } from "@tanstack/react-query";
import axios from "axios";

const addQuestionnaire = async (questionnaire: QuestionnaireType) => {
    const { data } = await axios.post("/questionnaire/create", questionnaire);
    return data;
};

export default function useAddQuestionnaire() {
    const mutation = useMutation({
        mutationFn: addQuestionnaire,
        onSuccess: () => {
            console.log("Questionnaire added");
        },
    });

    return mutation;
}
