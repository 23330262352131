// Components==============
import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import axios from "axios";
import { QuestionsType } from "typings/Database/Questions";
// =========================

const getQuestions = async () => {
    const { data } = await axios.get(`/questions`);
    return data as QuestionsType[];
};

export default function useGetQuestions(
    options?: UseQueryOptions<QuestionsType[]>,
) {
    const query = useQuery<QuestionsType[]>({
        queryKey: ["questions"],
        queryFn: () => getQuestions(),
        ...options,
    });

    return query;
}
