import { useParams } from "@tanstack/react-router";
import {
    BarElement,
    CategoryScale,
    ChartData,
    Chart as ChartJS,
    Legend,
    LinearScale,
    Title,
    Tooltip,
} from "chart.js";
import Chart from "chart.js/auto";
import { useEffect, useRef } from "react";

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
);

export const options = {
    indexAxis: "y" as const,
    plugins: {
        title: {
            display: false,
        },
        legend: {
            display: false,
        },
    },
    responsive: true,
    maintainAspectRatio: true,
    aspectRatio: 3 / 1,
    layout: {
        padding: 20,
    },
    scales: {
        x: {
            beginAtZero: false,
            display: false,
            stacked: true,
        },
        y: {
            beginAtZero: false,
            display: false,
            stacked: true,
        },
    },
};

interface CustomCanvasElement extends HTMLCanvasElement {
    chartInstance?: Chart;
}

type BarChartProps = {
    barChartData?: ChartData;
};

export function BarChart({ barChartData }: BarChartProps) {
    const chartRef = useRef<CustomCanvasElement>(null);

    const { questionnaireId } = useParams();

    function initializeChart(canvasElement, barChartData) {
        const ctx = canvasElement.getContext("2d");
        if (!ctx) return;

        canvasElement.chartInstance = new Chart(ctx, {
            type: "bar",
            data: barChartData,
            options: {
                ...options,
            },
        });
    }

    useEffect(() => {
        if (!chartRef.current) return;

        const chart = chartRef.current.chartInstance;
        if (!chart) {
            // Initialize chart if it doesn't exist
            initializeChart(chartRef.current, barChartData);
        } else {
            chart.update();
        }
    }, [barChartData, questionnaireId]);

    useEffect(() => {
        // Initial chart setup or update if barChartData changes
        if (chartRef.current) {
            const ctx = chartRef.current.getContext("2d");
            if (ctx) {
                // Cleanup existing chart instance if it exists
                if (chartRef.current.chartInstance) {
                    chartRef.current.chartInstance.destroy();
                }
                // Create a new chart instance with barChartData
                chartRef.current.chartInstance = new Chart(ctx, {
                    type: "bar",
                    data: {
                        datasets: barChartData?.datasets || [],
                        labels: barChartData?.labels,
                    },
                    options: {
                        ...options,
                    },
                });
            }
        }

        return () => {
            // Cleanup
            if (chartRef.current && chartRef.current.chartInstance) {
                chartRef.current.chartInstance.destroy();
                chartRef.current.chartInstance = undefined;
            }
        };
    }, [barChartData, questionnaireId]);

    return <canvas ref={chartRef} />;
}
