import RadarChart from "routes/Results/AllStatementsView/components/AllStatements/components/RadarChart/RadarChart";
import styles from "./Overlap.module.scss";

export default function Overlap() {
    return (
        <div className={styles.overlap}>
            <div className={styles.top}></div>
            <div className={styles.middle}>
                <RadarChart />
            </div>
        </div>
    );
}
