import styles from "./EnqueteHeader.module.scss";

export default function EnqueteHeader() {
    return (
        <div className={styles.enqueteHeader}>
            <p className={styles.optionDescription}>Opleiding</p>
            <p className={styles.optionDescription}>Open voor reactie</p>
            <p className={styles.optionDescription}>Zichtbaar in dashboard</p>
            <p className={styles.optionDescription}>URL Ervaren perspectief</p>
            <p className={styles.optionDescription}>
                URL Gerealiseerd perspectief
            </p>
            <p className={styles.optionDescription}>URL Beoogd perspectief</p>
            <p className={styles.optionDescription}>Bewerken</p>
            <p className={styles.optionDescription}>Exporteren</p>
        </div>
    );
}
