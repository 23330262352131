// Components==============
import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import axios from "axios";
// =========================

const getQuestionnaireByUrl = async (token: string) => {
    const { data } = await axios.get(`/questionnaire/url/${token}`);
    return data as QuestionnaireType;
};

export default function useGetQuestionnaireByUrl(
    token: string,
    options?: UseQueryOptions<QuestionnaireType>,
) {
    const query = useQuery<QuestionnaireType>({
        queryKey: ["questionnaireByUrl"],
        queryFn: () => getQuestionnaireByUrl(token),
        ...options,
    });

    return query;
}
