import { useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "@tanstack/react-router";
import useAddQuestionnaire from "actions/questionnaire/useAddQuestionnaire";
import Layout from "components/Layout/Layout";
import EditForm from "components/UI/EditForm/EditForm";
import { ChangeEvent, useState } from "react";
import styles from "./AddEnquete.module.scss";
import useGetQuestionnaireUsers from "actions/questionnaire/useGetQuestionnaireUsers";
import useGetUsers from "actions/questionnaire/useGetUsers";

export default function AddEnquete() {
    const { mutateAsync: addQuestionnaire } = useAddQuestionnaire();
    const { data: questionnaireUsers } = useGetQuestionnaireUsers();
    const { data: users } = useGetUsers();
    const [formValues, setFormValues] = useState<QuestionnaireType>({
        id: 0,
        school_name: "",
        open: 0,
        visible_in_dashboard: 0,
        experienced_role_name: "",
        intended_role_name: "",
        realized_role_name: "",
        owner_id: 0,
    });

    const navigate = useNavigate();

    const queryClient = useQueryClient();

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        setFormValues({
            ...formValues,
            [event.target.name]: event.target.value,
        });
    };

    const handleSubmit = (event: React.FormEvent) => {
        event.preventDefault();
        console.log("formValues", formValues);
        addQuestionnaire(formValues).then(() => {
            queryClient.invalidateQueries({ queryKey: ["questionnaires"] });
            navigate({ to: "/enquetes-overzicht" });
        });
    };

    return (
        <Layout>
            <div className={styles.edit}>
                <div className={styles.center}>
                    <h1>Opleiding toevoegen</h1>
                    <EditForm
                        handleSubmit={handleSubmit}
                        handleChange={handleChange}
                        formValues={formValues}
                        setFormValues={setFormValues}
                        buttonText="Toevoegen"
                        error=""
                        users={users}
                    />
                </div>
            </div>
        </Layout>
    );
}
