// Components==============
import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import axios from "axios";
// =========================

const getQuestionnaireUsers = async () => {
    const { data } = await axios.get(`/questionnaire/users`);
    return data;
};

export default function useGetQuestionnaireUsers(
    options?: UseQueryOptions<any[]>,
) {
    const query = useQuery<any[]>({
        queryKey: ["questions"],
        queryFn: () => getQuestionnaireUsers(),
        ...options,
    });

    return query;
}
