// Components==============
import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import axios from "axios";
// =========================

const getQuestionnaires = async () => {
    const { data } = await axios.get(`/questionnaires`);
    return data as QuestionnaireType[];
};

export default function useGetQuestionnaires(
    options?: UseQueryOptions<QuestionnaireType[]>,
) {
    const query = useQuery<QuestionnaireType[]>({
        queryKey: ["questionnaires"],
        queryFn: () => getQuestionnaires(),
        ...options,
    });

    return query;
}
