// Components==============
import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import axios from "axios";
import { ChartData } from "chart.js";
// =========================

const getRadarData = async (school: string) => {
    const { data } = await axios.get(`/radar/school/${school}`);
    return data as ChartData;
};

export default function useGetRadarData(
    school: string,
    options?: UseQueryOptions<ChartData>,
) {
    const query = useQuery<ChartData>({
        queryKey: ["radar"],
        queryFn: () => getRadarData(school),
        ...options,
    });

    return query;
}
