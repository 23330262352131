import { useMutation } from "@tanstack/react-query";
import axios from "axios";

const updateQuestionnaire = async (update: QuestionnaireType) => {
    const { data } = await axios.put(
        `/questionnaire/update/${update.id}`,
        update,
    );
    return data;
};

export default function useUpdateQuestionnaire() {
    const mutation = useMutation({
        mutationFn: updateQuestionnaire,
        onSuccess: () => {
            console.log("Questionnaire updated");
        },
    });

    return mutation;
}
