// Components==============
import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import axios from "axios";
// =========================

export const getUsers = async () => {
    const { data } = await axios.get("/users");
    return data as UserEntity[];
};

export default function useGetUsers(options?: UseQueryOptions<UserEntity[]>) {
    const query = useQuery<UserEntity[]>({
        queryKey: ["users"],
        queryFn: getUsers,
        ...options,
    });

    return query;
}
