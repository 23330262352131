import React, { createContext, useState } from "react";

type ResultsContextType = {
    answers: AnswerType[];
    setAnswers: (answers: AnswerType[]) => void;
    themes: ThemeType[];
    setThemes: (themes: ThemeType[]) => void;
    responses: ResponseDataType[];
    setResponses: (responses: ResponseDataType[]) => void;
    selectedTheme: number | null;
    setSelectedTheme: (themeId: number | null) => void;
};

export const ResultsContext = createContext<ResultsContextType>(
    {} as ResultsContextType,
);

type ResultsProviderProps = {
    children: React.ReactNode;
};

export default function ResultsProvider({ children }: ResultsProviderProps) {
    const [answers, setAnswers] = useState<AnswerType[]>([]);
    const [themes, setThemes] = useState<ThemeType[]>([]);
    const [responses, setResponses] = useState<ResponseDataType[]>([]);
    const [selectedTheme, setSelectedTheme] = useState<number | null>(null);

    return (
        <ResultsContext.Provider
            value={{
                answers,
                setAnswers,
                themes,
                setThemes,
                responses,
                setResponses,
                selectedTheme,
                setSelectedTheme,
            }}
        >
            {children}
        </ResultsContext.Provider>
    );
}
