import React from "react";
import { motion } from "framer-motion";
import { framerAnim } from "utilities/framer";

export default function Fade({ children }: { children: React.ReactNode }) {
    const opacity = {
        initial: { opacity: 0 },
        animate: { opacity: 1, transition: { duration: 0.5 } },
        exit: { opacity: 1 },
    };

    return <motion.div {...framerAnim(opacity)}>{children}</motion.div>;
}
