import { createLazyFileRoute } from "@tanstack/react-router";
import Nav from "components/Layout/components/Nav/Nav";

export default function About() {
    return (
        <>
            <Nav />
            <div className="p-2">Hello from About in 3 folder!</div>
        </>
    );
}
