import Fade from "components/Animations/Fade/Fade";
import Layout from "components/Layout/Layout";
import styles from "./End.module.scss";

export default function End() {
    return (
        <Layout>
            <Fade>
                <div className={styles.end}>
                    <h1>Bedankt voor het invullen!</h1>
                    <p>
                        Uw antwoorden zijn opgeslagen. U kunt de pagina sluiten.
                    </p>
                </div>
            </Fade>
        </Layout>
    );
}
