import { useQueryClient } from "@tanstack/react-query";
import { getUser } from "actions/user/useGetUser";
import axios from "axios";
import Layout from "components/Layout/Layout";
import Button from "components/UI/Button/Button";
import Input from "components/UI/Input/Input";
import { ChangeEvent, useState } from "react";
import styles from "./Login.module.scss";
import { useAuth } from "context/AuthContext";
import { useNavigate } from "@tanstack/react-router";
import useGetQuestionnaireUsers from "actions/questionnaire/useGetQuestionnaireUsers";
import useGetQuestionnaires from "actions/questionnaire/useGetQuestionnaires";

export default function Login() {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);

    const { data: questionnaireUsers } = useGetQuestionnaireUsers();
    const { data: questionnaires } = useGetQuestionnaires();

    const navigate = useNavigate();

    const { setUser } = useAuth();

    const queryClient = useQueryClient();

    const handleNameChange = (e: ChangeEvent<HTMLInputElement>) => {
        setEmail(e.target.value);
    };
    const handlePasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
        setPassword(e.target.value);
    };

    async function requestToken() {
        if (!email || !password) {
            return setError("Vul alle velden in");
        }

        try {
            console.log("requesting token");
            setLoading(true);
            // Request a new auth token
            // @ts-ignore
            const { data: token } = await axios
                .post("sanctum/token", {
                    email: email,
                    password: password,
                })
                .catch((r) => {
                    console.log(r);
                    if (r.response.status === 422) throw r;
                });
            // once the token is generated, put it in a secure store
            localStorage.setItem("authToken", token);
            // Set token as default header value in axios so requests are authenticated
            axios.defaults.headers.common.Authorization = `Bearer ${token}`;

            // Invalidate the user query to force a refetch
            await queryClient.invalidateQueries({ queryKey: ["user"] });

            // User has token so fetch it so we we can immediately display data on app load
            const userData = await queryClient.fetchQuery({
                queryKey: ["user"],
                queryFn: getUser,
            });

            setUser(userData);
            sessionStorage.setItem("userAuthenticated", "true");

            // Filter questionnaireUsers based on the user ID
            const matchedQuestionnaireUsers = questionnaireUsers?.filter(
                (qUser) => qUser.user_id === userData.id,
            );

            // Filter the resulting questionnaireUsers based on visible_in_dashboard
            const visibleQuestionnaire = matchedQuestionnaireUsers?.find(
                (qUser) => {
                    const questionnaire = questionnaires?.find(
                        (q) =>
                            q.id === qUser.questionnaire_id &&
                            q.visible_in_dashboard === 1,
                    );
                    return questionnaire !== undefined;
                },
            );

            if (userData?.role === "admin") {
                navigate({ to: "/enquetes-overzicht" });
            } else if (visibleQuestionnaire) {
                navigate({
                    to: `/resultaten/${visibleQuestionnaire.questionnaire_id}`,
                });
            } else {
                // Handle case where no matching visible questionnaire is found
                setError(
                    "No matching visible questionnaire found for the user",
                );
            }
        } catch (e: any) {
            console.log(e.response.data.message);
            const message = e.response.data.message;
            setLoading(false);
            if (message === "validation.email")
                return setError("Ongeldig mailadres");
            if (message === "The provided credentials are incorrect.")
                return setError("Gebruikersnaam of wachtwoord onjuist");
            setError("Gebruikersnaam of wachtwoord onjuist");
        }
    }

    const handleLogin = (event: React.FormEvent) => {
        event.preventDefault();
        requestToken();
    };

    return (
        <Layout>
            <div className={styles.login}>
                <div className={styles.center}>
                    <h1>Inloggen</h1>
                    <form onSubmit={handleLogin}>
                        {" "}
                        {/* Use onSubmit here */}
                        <div className={styles.block}>
                            <Input
                                type="text"
                                label="E-mail"
                                value={email}
                                name="name"
                                error={error}
                                onChange={handleNameChange}
                                placeholder="E-Mailadres"
                            />
                            <Input
                                type="password"
                                label="Wachtwoord"
                                value={password}
                                name="password"
                                error={error}
                                onChange={handlePasswordChange}
                                placeholder="Wachtwoord"
                            />
                        </div>
                        <Button>Inloggen</Button>{" "}
                        {/* Change button to submit type */}
                    </form>
                </div>
            </div>
        </Layout>
    );
}
