import { Link } from "@tanstack/react-router";
import useGetQuestionnaires from "actions/questionnaire/useGetQuestionnaires";
import Layout from "components/Layout/Layout";
import Button from "components/UI/Button/Button";
import EnqueteHeader from "./components/EnqueteHeader/EnqueteHeader";
import OptionsBar from "./components/OptionsBar/OptionsBar";
import styles from "./EnquetesOverview.module.scss";
import { Fragment } from "react/jsx-runtime";

export default function EnquetesOverview() {
    const { data: questionnaires } = useGetQuestionnaires();

    return (
        <Layout>
            <div className={styles.enquetesOverview}>
                <div className={styles.center}>
                    <h2 className={styles.heading}>Overzicht enquetes</h2>
                    <div className={styles.block}>
                        <EnqueteHeader />
                        <hr />
                        {questionnaires?.map((questionnaire) => (
                            <Fragment key={questionnaire.id}>
                                <OptionsBar questionnaire={questionnaire} />
                                <hr />
                            </Fragment>
                        ))}
                    </div>
                    <div className={styles.bottom}>
                        <Link to="/enquete-toevoegen">
                            <Button>Opleiding toevoegen</Button>
                        </Link>
                    </div>
                </div>
            </div>
        </Layout>
    );
}
