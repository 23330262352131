import styles from "./PageIndicator.module.scss";

type Props = {
    currentPage: number;
    numberOfPages: number;
};
export default function PageIndicator({ currentPage, numberOfPages }: Props) {
    return (
        <p className={styles.pageIndicator}>
            Pagina {currentPage}/{numberOfPages}
        </p>
    );
}
