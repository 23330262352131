import React from "react";
import Nav from "./components/Nav/Nav";
import styles from "./Layout.module.scss";

type Props = {
    children: React.ReactNode;
};

function Layout({ children }: Props) {
    return (
        <div className={styles.layout}>
            <Nav />
            <div className={styles.container}>{children}</div>
        </div>
    );
}

export default Layout;
