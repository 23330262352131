import { useNavigate, useParams } from "@tanstack/react-router";
import useGetQuestionnaireByUrl from "actions/questionnaire/useGetQuestionnaireByUrl";
import useGetThemes from "actions/theme/useGetThemes";
import Fade from "components/Animations/Fade/Fade";
import Layout from "components/Layout/Layout";
import BreadCrumbs from "components/UI/BreadCrumbs/BreadCrumbs";
import Button from "components/UI/Button/Button";
import PageIndicator from "components/UI/PageIndicator/PageIndicator";
import { Fragment, useEffect, useState } from "react";
import findMatchingType from "../../utilities/findMatchingType";
import EnqueteHeader from "./components/EnqueteHeader/EnqueteHeader";
import OptionsBar from "./components/OptionsBar/OptionsBar";
import styles from "./Enquete.module.scss";

export default function Enquete() {
    const { themeId, token } = useParams();
    const { data: themes } = useGetThemes();
    const theme = themes?.find((theme) => theme.id === parseInt(themeId));
    console.log("theme", themes);
    const [currentThemeIndex, setCurrentThemeIndex] = useState(0);

    const { data: questionnaire } = useGetQuestionnaireByUrl(token);

    const navigate = useNavigate();

    const type = findMatchingType(token, questionnaire);

    const transformType = (type?: string) => {
        switch (type) {
            case "experienced":
                return "Ervaren";
            case "realized":
                return "Gerealiseerd";
            case "intended":
                return "Beoogd";
        }
    };

    useEffect(() => {
        if (themes && currentThemeIndex + 1 > themes?.length) {
            navigate({ to: "/eind" });
        }
    }, [currentThemeIndex]);

    return (
        <Layout>
            <Fade>
                <div className={styles.enquete}>
                    <BreadCrumbs
                        crumbs={[
                            `${questionnaire?.school_name}`,
                            `${transformType(type)}`,
                        ]}
                    />
                    <div className={styles.center}>
                        {themes && currentThemeIndex + 1 > themes?.length ? (
                            <div className={styles.block}>
                                <h2 className={styles.heading}>
                                    Bedankt voor het invullen van de enquête!
                                </h2>
                                <p>
                                    Weet je zeker dat je de enquête wilt
                                    versturen?
                                </p>
                            </div>
                        ) : (
                            <>
                                <h2 className={styles.heading}>
                                    {themes?.[currentThemeIndex]?.name}
                                </h2>
                                <div className={styles.block}>
                                    <>
                                        <EnqueteHeader
                                            key={currentThemeIndex}
                                            name={
                                                theme?.questions?.[
                                                    currentThemeIndex
                                                ]?.sub_theme
                                            }
                                        />
                                        <hr />
                                        {themes?.[
                                            currentThemeIndex
                                        ]?.questions?.map((question, i) => (
                                            <Fragment key={i}>
                                                <OptionsBar
                                                    question={question}
                                                    type={questionnaire?.type}
                                                    currentThemeIndex={
                                                        currentThemeIndex
                                                    }
                                                />
                                                <hr />
                                            </Fragment>
                                        ))}
                                    </>
                                </div>
                            </>
                        )}
                        <div className={styles.bottom}>
                            <div className={styles.buttons}>
                                <Button
                                    inactive={currentThemeIndex === 0}
                                    onClick={() =>
                                        setCurrentThemeIndex(
                                            currentThemeIndex - 1,
                                        )
                                    }
                                    variant="white"
                                >
                                    Vorige
                                </Button>
                                <Button
                                    onClick={() =>
                                        setCurrentThemeIndex(
                                            currentThemeIndex + 1,
                                        )
                                    }
                                >
                                    {themes &&
                                    currentThemeIndex + 1 > themes?.length
                                        ? "Verstuur"
                                        : "Volgende"}
                                </Button>
                            </div>
                            {themes &&
                            currentThemeIndex + 1 > themes?.length ? (
                                <PageIndicator
                                    currentPage={currentThemeIndex + 1}
                                    numberOfPages={
                                        themes && themes.length
                                            ? themes.length
                                            : 1
                                    }
                                />
                            ) : null}
                        </div>
                    </div>
                </div>
            </Fade>
        </Layout>
    );
}
