import useGetRadarData from "actions/questionnaire/useGetRadarData";
import axios from "axios";
import {
    ChartData,
    Chart as ChartJS,
    Legend,
    LineElement,
    PointElement,
    RadialLinearScale,
    Title,
    Tooltip,
} from "chart.js";
import Chart from "chart.js/auto";
import Button from "components/UI/Button/Button";
import { ResultsContext } from "context/ResultsContext";
import { useContext, useEffect, useRef, useState } from "react";
import styles from "./RadarChart.module.scss";
import { useParams } from "@tanstack/react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/pro-regular-svg-icons";

ChartJS.register(
    RadialLinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
);

const options = {
    scales: {
        r: {
            ticks: {
                display: false,
                stepSize: 1,
            },
            suggestedMin: 1,
            suggestedMax: 5,
        },
    },
    elements: {
        line: {
            borderWidth: 3,
        },
    },
    plugins: {
        legend: {
            display: false,
        },
        title: {
            display: false,
        },

        layout: {
            padding: 20,
        },
    },
    maintainAspectRatio: true,
    responsive: true, // This can help ensure that the chart respects the parent container's dimensions.
};

// Extend the HTMLCanvasElement to include chartInstance for type safety
interface CustomCanvasElement extends HTMLCanvasElement {
    chartInstance?: Chart;
}

const RadarChart = () => {
    const chartRef = useRef<CustomCanvasElement>(null);
    const [visibility, setVisibility] = useState({
        Ervaren: true,
        Gerealiseerd: true,
        Beoogd: true,
    });

    const { questionnaireId } = useParams();

    const {
        data: radarData,
        isFetched,
        isFetching,
        refetch,
    } = useGetRadarData(questionnaireId);

    // console.log(radarData);
    const { selectedTheme } = useContext(ResultsContext);

    const [initialData, setInitialData] = useState<ChartData>({
        labels: [],
        datasets: [],
    });

    // Data fetching function
    const getRadarDataById = async (id: number, school: number) => {
        const { data } = await axios.get(`/radar/theme/${id}/${school}`);
        return data as ChartData;
    };

    useEffect(() => {
        refetch();
    }, [questionnaireId]);

    useEffect(() => {
        // Fetch and set radar data based on selectedTheme
        if (isFetched && !isFetching) {
            if (selectedTheme && selectedTheme !== 0) {
                const fetchData = async () => {
                    const radarData = await getRadarDataById(
                        selectedTheme,
                        questionnaireId,
                    );
                    console.log(radarData);
                    setInitialData(radarData);
                };

                fetchData();
            } else {
                // Set the initial data to the radarData to show all data as default
                setInitialData(radarData as ChartData);
            }
        }
    }, [selectedTheme, isFetched, questionnaireId, isFetching]);

    function initializeChart(canvasElement, initialData) {
        const ctx = canvasElement.getContext("2d");
        if (!ctx) return;

        canvasElement.chartInstance = new Chart(ctx, {
            type: "radar",
            data: initialData,
            options: {
                ...options,
            },
        });
    }

    useEffect(() => {
        // Initial chart setup or update if initialData changes
        if (chartRef.current && isFetched) {
            const ctx = chartRef.current.getContext("2d");
            if (ctx) {
                // Cleanup existing chart instance if it exists
                if (chartRef.current.chartInstance) {
                    chartRef.current.chartInstance.destroy();
                }
                // Create a new chart instance with initialData
                chartRef.current.chartInstance = new Chart(ctx, {
                    type: "radar",
                    datasets: initialData?.datasets?.filter(
                        (dataset) =>
                            visibility[
                                dataset.label as keyof typeof visibility
                            ],
                    ),
                    options: {
                        ...options,
                    },
                });
            }
        }

        return () => {
            // Cleanup
            if (chartRef.current && chartRef.current.chartInstance) {
                chartRef.current.chartInstance.destroy();
                chartRef.current.chartInstance = undefined;
            }
        };
    }, [initialData]); // Add initialData to the dependency array

    useEffect(() => {
        if (!chartRef.current) return;

        const chart = chartRef.current.chartInstance;
        if (!chart) {
            // Initialize chart if it doesn't exist
            initializeChart(chartRef.current, radarData);
        } else {
            // Update chart data
            const filteredData = {
                ...initialData,
                datasets: initialData?.datasets?.filter(
                    (dataset) =>
                        visibility[dataset.label as keyof typeof visibility],
                ),
            };

            chart.data = filteredData;
            chart.update();
        }
    }, [initialData, visibility, questionnaireId]);

    const handleVisibilityToggle = (type: string) => {
        setVisibility((prev) => ({ ...prev, [type]: !prev[type] }));
    };

    return (
        <div className={styles.container}>
            {isFetched ? (
                <>
                    <div className={styles.canvasContainer}>
                        <div className={styles.canvasLabelMax}>
                            Helemaal mee eens
                        </div>
                        <canvas ref={chartRef} />
                        <div className={styles.canvasLabelMin}>
                            Helemaal mee oneens
                        </div>
                    </div>
                    <div className={styles.buttons}>
                        <Button
                            variant={visibility["Ervaren"] ? "red" : "white"}
                            onClick={() => handleVisibilityToggle("Ervaren")}
                        >
                            Ervaren
                        </Button>
                        <Button
                            variant={
                                visibility["Gerealiseerd"]
                                    ? "turqoise"
                                    : "white"
                            }
                            onClick={() =>
                                handleVisibilityToggle("Gerealiseerd")
                            }
                        >
                            Gerealiseerd
                        </Button>
                        <Button
                            variant={visibility["Beoogd"] ? "primary" : "white"}
                            onClick={() => handleVisibilityToggle("Beoogd")}
                        >
                            Beoogd
                        </Button>
                    </div>
                </>
            ) : (
                <FontAwesomeIcon icon={faSpinner} spin />
            )}
        </div>
    );
};

export default RadarChart;
