export default function findMatchingType(
    token: string,
    questionnaire?: QuestionnaireType,
): string | undefined {
    const fields = ["experienced_url", "realized_url", "intended_url"];

    for (let field of fields) {
        if (questionnaire?.[field as keyof QuestionnaireType] === token) {
            if (field === "experienced_url") {
                return "experienced";
            }
            if (field === "realized_url") {
                return "realized";
            }
            if (field === "intended_url") {
                return "intended";
            }
        }
    }

    return undefined;
}
