import { useContext } from "react";
import styles from "./AllStatementsView.module.scss";
import AllStatements from "./components/AllStatements/AllStatements";
import { ResultsContext } from "context/ResultsContext";
import { useParams } from "@tanstack/react-router";
import useGetResponses from "actions/questionnaire/useGetResponses";

export default function AllStatementsView() {
    const { data: responses } = useGetResponses();
    const { questionnaireId } = useParams();
    const filteredResponses = responses?.filter(
        (res) => res.questionnaire_id === parseInt(questionnaireId),
    );
    return (
        <div className={styles.content}>
            <AllStatements />
            <div className={styles.right}>
                <h3>Legenda</h3>
                <div className={styles.perspectiveName}>
                    <h4 className={styles.red}>Ervaren perspectief</h4>
                    <p>{`${filteredResponses?.filter((res) => res.type === "experienced").length} personen`}</p>
                </div>
                <div className={styles.perspectiveName}>
                    <h4 className={styles.turqoise}>
                        Gerealiseerd perspectief
                    </h4>
                    <p>{`${filteredResponses?.filter((res) => res.type === "realized").length} personen`}</p>
                </div>
                <div className={styles.perspectiveName}>
                    <h4 className={styles.blue}>Beoogd perspectief</h4>
                    <p>{`${filteredResponses?.filter((res) => res.type === "intended").length} personen`}</p>
                </div>
                <hr />
                <div className={styles.colorsWrapper}>
                    <div className={styles.colors}>
                        <div className={styles.red1} />
                        <div className={styles.turqoise1} />
                        <div className={styles.blue1} />
                    </div>
                    <p className={styles.text}>Helemaal mee eens</p>
                </div>
                <div className={styles.colorsWrapper}>
                    <div className={styles.colors}>
                        <div className={styles.red08} />
                        <div className={styles.turqoise08} />
                        <div className={styles.blue08} />
                    </div>
                    <p className={styles.text}>Mee eens</p>
                </div>
                <div className={styles.colorsWrapper}>
                    <div className={styles.colors}>
                        <div className={styles.red06} />
                        <div className={styles.turqoise06} />
                        <div className={styles.blue06} />
                    </div>
                    <p className={styles.text}>Neutraal</p>
                </div>
                <div className={styles.colorsWrapper}>
                    <div className={styles.colors}>
                        <div className={styles.red04} />
                        <div className={styles.turqoise04} />
                        <div className={styles.blue04} />
                    </div>
                    <p className={styles.text}>Mee oneens</p>
                </div>
                <div className={styles.colorsWrapper}>
                    <div className={styles.colors}>
                        <div className={styles.red02} />
                        <div className={styles.turqoise02} />
                        <div className={styles.blue02} />
                    </div>
                    <p className={styles.text}>Helemaal mee oneens</p>
                </div>
            </div>
        </div>
    );
}
