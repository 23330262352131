// Components==============
import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import axios from "axios";
// =========================

const getAnswers = async () => {
    const { data } = await axios.get(`/answers`);
    return data as AnswerType[];
};

export default function useGetAnswers(options?: UseQueryOptions<AnswerType[]>) {
    const query = useQuery<AnswerType[]>({
        queryKey: ["answers"],
        queryFn: getAnswers,
        ...options,
    });

    return query;
}
