import {
    faCheckCircle,
    faCopy,
    faEdit,
    faFileExport,
    faTimesCircle,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AnimatedDiv from "components/UI/AnimatedDiv/AnimatedDiv";
import { useEffect, useState } from "react";
import styles from "./OptionsBar.module.scss";
import { Link } from "@tanstack/react-router";
import axios from "axios";

type OptionsBarProps = {
    questionnaire: QuestionnaireType;
};

export default function OptionsBar({ questionnaire }: OptionsBarProps) {
    const [copiedText, setCopiedText] = useState<string | null>(null);
    const copyTextToClipboard = async (text: string): Promise<void> => {
        try {
            await navigator.clipboard.writeText(text);
            console.log("Text copied to clipboard");
            setCopiedText(text);
        } catch (err) {
            console.error("Failed to copy: ", err);
        }
    };

    const handleDivClick = (event: React.MouseEvent): void => {
        event.preventDefault();
        // Step 2: Get the target element from the event
        const target = event.target as HTMLElement;

        // Step 3: Find the closest parent div with the class `${styles.url}`
        const parentDiv = target.closest(`.${styles.url}`);

        // Ensure the parentDiv is not null and is a div element
        if (parentDiv && parentDiv instanceof HTMLDivElement) {
            // Step 4: Within this parent div, find the `p` element to get its text content
            const textElement = parentDiv.querySelector("p");

            if (textElement) {
                const text = textElement.textContent || "";
                // Step 5: Pass this text content to the `copyTextToClipboard` function
                copyTextToClipboard(text);
            }
        }
    };

    const handleExport = async (questionnaireId: number) => {
        try {
            const response = await axios.get(
                `/questionnaire/export/${questionnaireId}`,
                {
                    responseType: "blob",
                },
            );

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", `${questionnaire.school_name}.csv`);
            document.body.appendChild(link);
            link.click();
        } catch (error) {
            console.error("Error exporting data:", error);
        }
    };

    const urlPlaceholder = window.location.origin + "/welkom/";

    return (
        <div className={styles.OptionsBar}>
            <p className={styles.name}>{questionnaire.school_name}</p>
            <AnimatedDiv className={`${styles.status}`}>
                <FontAwesomeIcon
                    icon={questionnaire.open ? faCheckCircle : faTimesCircle}
                    color={questionnaire.open ? "#32d09d" : "#fd0e29"}
                />
            </AnimatedDiv>
            <AnimatedDiv className={`${styles.status}`}>
                <FontAwesomeIcon
                    icon={
                        questionnaire.visible_in_dashboard
                            ? faCheckCircle
                            : faTimesCircle
                    }
                    color={
                        questionnaire.visible_in_dashboard
                            ? "#32d09d"
                            : "#fd0e29"
                    }
                />
            </AnimatedDiv>
            <AnimatedDiv className={styles.url} onClick={handleDivClick}>
                <p>{`${urlPlaceholder}${questionnaire?.experienced_url}`}</p>
                <FontAwesomeIcon
                    icon={
                        copiedText ===
                        urlPlaceholder + questionnaire?.experienced_url
                            ? faCheckCircle
                            : faCopy
                    }
                />
            </AnimatedDiv>
            <AnimatedDiv className={styles.url} onClick={handleDivClick}>
                <p>{`${urlPlaceholder}${questionnaire?.realized_url}`}</p>
                <FontAwesomeIcon
                    icon={
                        copiedText ===
                        urlPlaceholder + questionnaire?.realized_url
                            ? faCheckCircle
                            : faCopy
                    }
                />
            </AnimatedDiv>
            <AnimatedDiv className={styles.url} onClick={handleDivClick}>
                <p>{`${urlPlaceholder}${questionnaire?.intended_url}`}</p>
                <FontAwesomeIcon
                    icon={
                        copiedText ===
                        urlPlaceholder + questionnaire?.intended_url
                            ? faCheckCircle
                            : faCopy
                    }
                />
            </AnimatedDiv>
            <AnimatedDiv className={styles.iconWrapper}>
                <Link to={`/enquete-bewerken/${questionnaire.id}`}>
                    <FontAwesomeIcon icon={faEdit} />
                </Link>
            </AnimatedDiv>
            <AnimatedDiv
                onClick={() => handleExport(questionnaire.id)}
                className={styles.iconWrapper}
            >
                <FontAwesomeIcon icon={faFileExport} />
            </AnimatedDiv>
        </div>
    );
}
